// Accepts a Mapbox GL JS layer, and returns a React component that inclues the
// rendered legend
import React, { Component } from 'react'
import { scaleLinear } from 'd3-scale'
import SVG from 'react-inlinesvg'
import _ from 'lodash'
import { Button } from 'react-bootstrap';

import {
  EYE_OPEN_ICON, EYE_CLOSE_ICON, CLOSE_ICON
} from '../../data/data.js'

const range = (start, stop, step = 1) =>
  Array(Math.ceil((stop - start) / step))
    .fill(start)
    .map((x, y) => x + y * step)

const isFill = (layer) => {
  return !!(layer.type === 'fill' && Array.isArray(layer.paint['fill-color']) && layer.paint['fill-color'][0] === 'interpolate')
}

const isGraduatedCircle = (layer) => {
  return !!(layer.type === 'circle' && Array.isArray(layer.paint['circle-radius']) && layer.paint['circle-radius'][0] === 'interpolate')
}

const isSymbol = (layer) => {
  return !!(layer.type === 'symbol' && layer.layout['icon-image'])
}

const graduatedCircleLegend = (width, height, props, layer) => {
  const func = layer.paint['circle-radius'] // Interpolation function
  if (func[1][0] !== 'linear') return // Must be linear interpolation
  const domainLow = func[3]
  const domainHigh = func[5]
  const rangeLow = func[4]
  const rangeHigh = func[6]
  const domain = [domainLow, domainHigh]
  const dataRange = [rangeLow, rangeHigh]
  const domainMid = (domainLow + domainHigh) / 2
  const samples = [domainLow, domainMid, domainHigh].map(Math.floor)
  const domainLowLabel = `< ${domainLow}`
  const domainHighLabel = `${domainHigh} +`
  const domainMidLabel = domainMid ? `${Math.floor(domainMid)}` : ""
  const labels = [
    domainLowLabel, domainMidLabel, domainHighLabel
  ]
  const labelData = _.zip(labels, [0, width/2, width], ['start', 'middle', 'end']).map(d => {
    return {
      text: d[0],
      x: d[1],
      textAnchor: d[2]
    }
  })
  const scale = scaleLinear().domain(domain).range(dataRange)
  const fill = layer.paint['circle-color'] || '#024486'
  const labelProps = {
    alignmentBaseline: 'middle',
    fill: 'currentColor',
    y: height*0.75
  }
  return (
    <div>
      {
        props.legendText && (<span className="legend-text">{props.legendText}</span>)
      }
      <svg width={width} height={height}>
        {
          samples.map((sample, i) => {
            const r = scale(sample)
            let x
            switch (i) {
              case 0:
                x = 0 + r
                break
              case 1:
                x = width / 2
                break
              case 2:
                x = width - r
                break
              default:
                x = (width / samples.length * i)
            }
            return (
              <g key={i}>
                <circle
                  key={`circle-${i}`}
                  cx={x}
                  cy={height*0.25}
                  r={r}
                  opacity={layer.paint['circle-opacity'] || 1}
                  stroke={layer.paint['circle-stroke-color'] || '#ffffff'}
                  strokeWidth={layer.paint['circle-stroke-width'] || 0}
                  fill={fill}
                />
              </g>
            )
          })
        }
        <g>
          {
            labelData.map((lbl, i) => {
              return (
                <text key={i} {...labelProps} {..._.omit(lbl, 'text')}>{lbl.text}</text>
              )
            })
          }
        </g>
      </svg>
    </div>
  )
}

const linearColourLegend = (width, height, props, layer) => {
  const func = layer.paint['fill-color'] // Interpolation function
  if (func[1][0] !== 'linear') return // Must be linear interpolation
  const domainLow = func[3]
  const domainHigh = func[5]
  const rangeLow = func[4]
  const rangeHigh = func[6]
  const samples = range(domainLow, domainHigh, (domainHigh - domainLow) / 5).map(x => Math.ceil(x))
  samples.push(domainHigh)
  const domainLowLabel = `< ${domainLow}`
  const domainHighLabel = `${domainHigh} +`
  const domainMid = Math.round((domainHigh + domainLow) / 2.0)
  const domainMidLabel = ((domainMid !== domainLow) && (domainMid !== domainHigh)) ? `${domainMid}` : ""
  const labelProps = {
    y: 50,
    fill: 'currentColor',
    alignmentBaseline: 'middle'
  }
  return (
    <div key={layer.id}>
      {
        props.legendText && (<span className="legend-text">{props.legendText}</span>)
      }
      <svg width={width} height={height}>
        <defs>
          <linearGradient id={layer.id}>
            <stop className="low" offset="0%" stopColor={rangeLow}/>
            <stop className="high" offset="100%" stopColor={rangeHigh}/>
          </linearGradient>
        </defs>
        <g>
          <rect width={width} height={15} x={0} y={15} fill={`url(#${layer.id})`}/>
          <text {...labelProps} x={0} textAnchor={'start'}>{domainLowLabel}</text>
          <text {...labelProps} x={width/2} textAnchor={'middle'}>{domainMidLabel}</text>
          <text {...labelProps} x={width} textAnchor={'end'}>{domainHighLabel}</text>
        </g>
      </svg>
    </div>
  )
}

const symbolLegend = (width, height, props, layer) => {
  return (
    <div>
      <SVG className="legend-symbol-icon" src={`${process.env.REACT_APP_BASENAME}/images/icons/${layer.layout["icon-image"]}.svg`} />
      {
        props.legendText && (<span className="legend-text">{props.legendText}</span>)
      }
    </div>
  )
}

const fallbackLegend = (width, height, props, layer) => {
  return (
    <div>
      {
        props.legendText && (<span className="legend-text symbol-legend-text">{props.legendText}</span>)
      }
    </div>
  )
}

const graphic = ({layer, width, height, props}) => {
  if (isGraduatedCircle(layer)) {
    return graduatedCircleLegend(width, height, props, layer)
  } else if (isFill(layer)) {
    return linearColourLegend(width, height, props, layer)
  } else if (isSymbol(layer)) {
    return symbolLegend(width, height, props, layer)
  } else {
    return fallbackLegend(width, height, props, layer)
  }
}

class Legend extends Component {
  removeLayer() {
    this.props.removeMapLayer()
  }
  toggleVisibility() {
    this.props.toggleVisibility()
  }
  render() {
    const layer = this.props.layer
    if (!layer) return null
    const height = 58 // this.props.height
    const width = this.props.width * 0.8
    const isVisible = layer.layout.visibility === 'visible'
    return (
      <div className="legend-graphic-container">
        <div className="column-main" style={{filter: !isVisible ? "blur(1px)" : "initial"}}>
          {graphic({layer, width, height, props: this.props})}
        </div>
        <div className="column-side legend-controls-container">
          <div className="legend-controls-container-inner">
            <Button onClick={this.removeLayer.bind(this)}>
              <SVG src={CLOSE_ICON} style={{fill: 'currentColor'}}/>
            </Button>
          </div>
          <div className="legend-controls-container-inner">
            <Button onClick={this.toggleVisibility.bind(this)}>
              <SVG src={isVisible ? EYE_OPEN_ICON : EYE_CLOSE_ICON} style={{fill: 'currentColor'}}/>
            </Button>
          </div>
        </div>
      </div>
    )
   }
}
export default Legend
