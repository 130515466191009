import React, { Component } from 'react'
import { Popup } from 'mapbox-gl'
import ReactMapboxGl, { ZoomControl, RotationControl } from 'react-mapbox-gl'
import ContainerDimensions from 'react-container-dimensions'
import SVG from 'react-inlinesvg'
import { Button } from 'react-bootstrap'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import Legend from '../Legend/Legend'

let mapScopeLeak = null
let hoverLayers = new Set([])
let clickLayers = new Set([])

const pointCursorOn = (e) => {
  mapScopeLeak.getCanvas().style.cursor = 'pointer'
}

const resetCursor = (e) => {
  mapScopeLeak.getCanvas().style.cursor = ''
}

const hoverPopup = new Popup({
  closeButton: false, closeOnClick: false
})

const clickPopup = new Popup({
  closeButton: true, closeOnClick: false
})

const re = /{([\w\d]*)}/g

export class ReactMap extends Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.hideNotice = this.hideNotice.bind(this)
    this.state = {
      ReactMapComponent: ReactMapboxGl({
        accessToken: this.props.mapApiKey
      }),
      showNotice: true // TODO on receive props, new notice, set true again; and set false by default
    }
  }
  hideNotice() {
    this.props.setDocument(null)
    this.setState({showNotice: true})
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (mapScopeLeak) {
      mapScopeLeak.resize()
      Object.keys(this.props.overlays).forEach(layerId => {
        const layoutProps = this.props.overlays[layerId].layoutProps
        Object.keys(layoutProps).forEach(layoutProp => {
          const currentValue = mapScopeLeak.getLayoutProperty(layerId, layoutProp)
          if (currentValue !== layoutProps[layoutProp]) {
            mapScopeLeak.setLayoutProperty(layerId, layoutProp, layoutProps[layoutProp])
          }
        })
        const hover = this.props.overlays[layerId].hover || false
        const click = this.props.overlays[layerId].click || false
        if (hover && !hoverLayers.has(layerId)) {
          // Establish a new hover layer
          hoverLayers.add(layerId)
          mapScopeLeak.on('mouseenter', layerId, pointCursorOn)
          mapScopeLeak.on('mouseleave', layerId, resetCursor)
          if (hover && typeof(hover) === "string") {
            // Enable a hovering popup
            mapScopeLeak.on('mousemove', layerId, e => {
              const feature = e.features[0]
              const coordinates = e.lngLat
              let content = hover.slice()
              let m
              do {
                m = re.exec(content)
                if (m) {
                  content = content.replace(m[0], feature.properties[m[1]])
                }
              } while (m);
              hoverPopup.setLngLat(coordinates)
                .setHTML(content)
                .addTo(mapScopeLeak);
            })
            mapScopeLeak.on('mouseleave', layerId, () => {
              hoverPopup.remove()
            })
          }
        } else if (!hover && hoverLayers.has(layerId)) {
          hoverLayers.delete(layerId)
          mapScopeLeak.off('mouseenter', layerId, pointCursorOn)
          mapScopeLeak.off('mouseleave', layerId, resetCursor)
        }
        if (click && !clickLayers.has(layerId)) {
          mapScopeLeak.on('mouseenter', layerId, pointCursorOn)
          mapScopeLeak.on('mouseleave', layerId, resetCursor)
          if (click && typeof(click) === 'string') {
            // Enable a click popup
            mapScopeLeak.on('click', layerId, e => {
              const feature = e.features[0]
              // const coordinates = e.lngLat
              let content = click.slice()
              let m
              do {
                m = re.exec(content)
                if (m) {
                  content = content.replace(m[0], feature.properties[m[1]])
                }
              } while (m);
                this.props.setDocument(content)
            })
          }
        } else if (!click && clickLayers.has(layerId)) {
          clickLayers.delete(layerId)
          mapScopeLeak.off('mouseenter', layerId, pointCursorOn)
          mapScopeLeak.off('mouseleave', layerId, resetCursor)
        }
      })
    } else if (this.props.documentHtml !== prevProps.documentHtml) {
      this.setState({showNotice: true})
    }
  }
  render() {
    const ReactMapComponent = this.state.ReactMapComponent
    const doRef = map => {
      if (!mapScopeLeak) {
        mapScopeLeak = map
        this.props.styleLoaded({
          style: mapScopeLeak.getStyle()
        })
      }
    }
    return (
      <div id="map-concern-container" className="map-concern-container modal-container" ref={this.containerRef}>
        {
          (!this.props.overlays || !Object.keys(this.props.overlays).length) ? null
          : (
            <div className="map-element-container-outer legend-container-outer">
            {
              Object.keys(this.props.overlays).map(id => {
                const style = this.props.overlays[id].style
                if (!style) return null
                return (
                  <div className="legend-container-inner" key={`${style.id}-${style['source-layer']}`}>
                    <ContainerDimensions>
                      <Legend
                        layer={style}
                        legendText={this.props.overlays[id].legendText}
                        removeMapLayer={() => this.props.removeMapLayer(id)}
                        toggleVisibility={() => this.props.toggleVisibility(id)}
                      />
                    </ContainerDimensions>
                  </div>
                )
              })
            }
            </div>
          )
        }
        <TransitionGroup component={null}>
        {
          this.props.documentHtml && (
            <CSSTransition classNames="modal-transition menu-transition" timeout={600}>
              <div className="notice-modal modal-container">
                <div className="notice-modal-header">
                  <Button className="splash-close" onClick={this.hideNotice}>
                    <SVG src={this.props.closeIcon}/>
                  </Button>
                </div>
                <section className="notice-modal-body">
                  <div dangerouslySetInnerHTML={{ __html: this.props.documentHtml }}></div>
                </section>
              </div>
            </CSSTransition>
          )
        }
        </TransitionGroup>

        <ReactMapComponent
          onStyleLoad={doRef}
          style={this.props.mapStyle}
          containerStyle={{
            height: `${this.props.height}px`,
            width: `${this.props.width}px`
          }}
          zoom={this.props.zoom}
          center={this.props.center}
          injectCss={true}
        >
          <ZoomControl position={'top-right'}/>
          <RotationControl position={'top-right'}/>
        </ReactMapComponent>
        {this.props.children}
      </div>
    );
  }
}
