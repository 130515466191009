import React, { Component } from 'react'
import { Grid, Row, Col, Button } from 'react-bootstrap'
import ContainerDimensions from 'react-container-dimensions'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import SVG from 'react-inlinesvg'
import _ from 'lodash'

import { Sidebar } from './Sidebar/Sidebar'
import { Midbar } from './Midbar/Midbar'
import { ReactMap } from './Map/Map'
import { SplashModal } from './SplashModal'
import { TopBar } from './TopBar'

const MAP_WIDTH = 8
const SIDEBAR_WIDTH = 12 - MAP_WIDTH

// const resizeWindow = () => window.dispatchEvent(new Event('resize'));

export class MainLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      midBarData: {},
      midBarType: null,
      overlays: {},
      activeLayerIds: [],
      mapStyle: null,
      documentHtml: null,
      midBarActive: false,
      noMidBar: false,
    }
    this.setMidBarCode = this.setMidBarCode.bind(this)
    this.setMidBarArea = this.setMidBarArea.bind(this)
    this.setMidBarMembers = this.setMidBarMembers.bind(this)
    this.changeMapLayers = this.changeMapLayers.bind(this)
    this.resetMidBarToDefault = this.resetMidBarToDefault.bind(this)
    this.styleLoaded = this.styleLoaded.bind(this)
    this.getLayerFromStyle = this.getLayerFromStyle.bind(this)
    this.setDocument = this.setDocument.bind(this)
    this.unsetDocument = this.unsetDocument.bind(this)
    this.unsetMidBar = this.unsetMidBar.bind(this)
    this.toggleMidBar = this.toggleMidBar.bind(this)
  }
  styleLoaded({style}) {
    this.setState({
      mapStyle: style
    })
  }
  unsetMidBar() {
    this.setState({
      midBarType: null,
      midBarData: {},
      midBarActive: false
    })
  }
  toggleMidBar() {
    this.setState({
      noMidBar: !this.state.noMidBar,
      midBarActive: false
    }, () => {
      // console.log('dispatch dummy resize')
      // resizeWindow();
    })
  }
  setMidBarCode(code) {
    this.setState({
      midBarType: 'sportsCode',
      midBarData: code,
      midBarActive: true
    })
  }
  setMidBarArea(area) {
    this.setState({
      midBarType: 'area',
      midBarData: area,
      midBarActive: true
    })
  }
  setMidBarMembers(members) {
    this.setState({
      midBarType: 'members',
      midBarData: members,
      midBarActive: true
    })
  }
  resetMidBarToDefault() {
    this.setState({
      midBarData: {},
      midBarType: null,
      midBarActive: true
    })
  }
  setDocument(documentHtml) {
    this.setState({
      documentHtml
    })
  }
  unsetDocument() {
    this.setState({
      documentHtml: null
    })
  }
  getLayerFromStyle(layerId) {
    // Returns the layer matching the layerId in the map style, else undefined
    if (!layerId || !this.state.mapStyle || !this.state.mapStyle.layers || !this.state.mapStyle.layers.length) return
    return this.state.mapStyle.layers.find(l => l.id === layerId)
  }
  changeMapLayers(layers, methods) {
    const overlays = Object.assign({}, this.state.overlays)
    if (!layers || !layers.length || !methods || !methods.length || methods.length !== layers.length) return
    let changes = {}
    changes = layers.reduce((acc, layer, idx) => {
      if (!layer) return acc
      if (_.isString(layer)) {
        layer = this.state.overlays[layer]
      }
      const style = this.getLayerFromStyle(layer.id) || {}
      if (methods[idx] === 'add') {
        style.layout = {
          ...style.layout,
          visibility: 'visible'
        }
        acc[layer.id] = {
          ...layer,
          style,
          layoutProps: {'visibility': 'visible'},
          layerName: layer.layerName || layer.name || layer.id,
          active: true
        }
      } else if (methods[idx] === 'remove') {
        acc[layer.id] = {
          ...layer,
          style: null,
          layoutProps: {'visibility': 'none'},
          hover: false,
          click: false,
          legendText: null,
          layerName: null,
          active: false
        }
      } else if (methods[idx] === 'toggleVisibility') {
        const oldVisibility = style.layout.visibility
        const newVisibility = oldVisibility === 'visible' ? 'none' : 'visible'
        style.layout = {
          ...style.layout,
          visibility: newVisibility
        }
        acc[layer.id] = {
          ...layer,
          style,
          layoutProps: {'visibility': newVisibility},
          active: true,
          layerName: layer.layerName || layer.name || layer.id
        }
      }
      return acc
    }, {})
    const newOverlays = Object.assign(overlays, changes)
    this.setState({
      overlays: newOverlays,
      activeLayerIds: Object.keys(newOverlays).filter(layerId => !!newOverlays[layerId].active)
    })
  }
  render() {
    return (
      <Grid fluid>
        <SplashModal
          affiliates={this.props.affiliates}
          logo={this.props.logo}
          logoAlt={this.props.logoAlt}
          splash={this.props.splash}
        />
        <Row>
          <TopBar logout={this.props.logout}></TopBar>
          {!this.state.noMidBar && (
            <TransitionGroup component={null}>
              <CSSTransition classNames="menu-transition" timeout={300}>
                <Col className={'major-col'} md={SIDEBAR_WIDTH}>
                  <TransitionGroup component={null}>
                    {!this.state.midBarActive && (
                      <CSSTransition classNames="menu-transition" timeout={300}>
                        <Sidebar
                          logo={this.props.logo}
                          logoAlt={this.props.logoAlt}
                          disclaimer={this.props.disclaimer}
                          commentary={this.props.commentary}
                          helpIcon={this.props.helpIcon}
                          instructions={this.props.instructions}
                          forwardIcon={this.props.forwardIcon}
                          closeIcon={this.props.closeIcon}
                          categories={this.props.categories}
                          handleSportsCode={this.setMidBarCode}
                          handleAreaViewer={this.setMidBarArea}
                          handleMemberViewer={this.setMidBarMembers}
                          helpButtonClick={this.resetMidBarToDefault}
                          closeButtonClick={this.toggleMidBar}
                        />
                      </CSSTransition>
                    )}
                  </TransitionGroup>
                  <TransitionGroup component={null}>
                    {this.state.midBarActive && (
                      <CSSTransition classNames="menu-transition-ltr" timeout={300}>
                        <Midbar
                          midBarType={this.state.midBarType}
                          midBarData={this.state.midBarData}
                          mapStyle={this.state.mapStyle}
                          addMapLayer={(layer) => this.changeMapLayers([layer], ['add'])}
                          removeMapLayer={(layerId) => this.changeMapLayers([layerId], ['remove'])}
                          changeMapLayers={this.changeMapLayers}
                          setDocument={this.setDocument}
                          activeLayers={this.state.activeLayerIds}
                          onBackClick={this.unsetMidBar}
                          onQuitClick={this.toggleMidBar}
                          instructions={this.props.instructions}
                        />
                      </CSSTransition>
                    )}
                  </TransitionGroup>
                </Col>
              </CSSTransition>
            </TransitionGroup>
          )}
          <Col id="map-container" className={'major-col map-container'} md={this.state.noMidBar ? 12 : MAP_WIDTH}>
            <ContainerDimensions>
              {
                ({height, width, left, top, right, bottom}) => {
                  return (
                    <ReactMap
                      mapStyle={this.props.mapStyle}
                      mapApiKey={this.props.mapApiKey}
                      height={height}
                      width={width}
                      top={top}
                      left={left}
                      zoom={this.props.mapZoom}
                      center={this.props.mapCenter}
                      overlays={this.state.overlays}
                      styleLoaded={({style}) => this.styleLoaded({style})}
                      closeIcon={this.props.closeIcon}
                      removeMapLayer={(layer) => this.changeMapLayers([layer], ['remove'])}
                      toggleVisibility={(layerId) => this.changeMapLayers([layerId], ['toggleVisibility'])}
                      documentHtml={this.state.documentHtml}
                      setDocument={this.setDocument}
                    >
                      <div style={{visibility: this.state.noMidBar ? 'visible': 'hidden'}}>
                        <Button className="btn-menu-burger" onClick={this.toggleMidBar}>
                          <SVG src={this.props.burgerIcon} style={{fill: 'currentColor'}}/>
                        </Button>
                      </div>
                    </ReactMap>
                  )
                }
              }
            </ContainerDimensions>
          </Col>
        </Row>
      </Grid>
    );
  }
}
