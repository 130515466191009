import React, { Component } from 'react'

import Splash from './Splash/Splash'
import { MustLogin } from './MustLogin';

import IMAGE from './map_desat.png';

import {
  LOGO,
  LOGO_ALT,
  DISCLAIMER,
  APP_NAME
} from './data/data.js'

import './styles/css/index.css'

class App extends Component {
  goTo(route) {
    this.props.history.replace(route)
  }
  render() {
    const basepath = process.env.REACT_APP_BASENAME
    const logo = basepath + LOGO
    const { isAuthenticated } = this.props.auth;
    return (
      <div>
        <div class="test">
          <img src={IMAGE} alt="website image" style={{height: '100vh'}} />
        </div>
        {
          this.props.location.pathname === '/' && isAuthenticated() ? (
            <Splash
              auth={this.props.auth}
              goTo={(route) => this.goTo(route)}
            />
          ) : null
        }
        {
          this.props.location.pathname === '/' && !isAuthenticated() ? (
            <MustLogin
              history={this.props.history}
              auth={this.props.auth}
              logo={logo}
              logoAlt={LOGO_ALT}
              appName={APP_NAME}
              disclaimer={DISCLAIMER}
            />
          ) : null
        }
      </div>
    )
  }
}

export default App;
